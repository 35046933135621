<template>
    <div>
        <Pane />
        <a-card class="container">
            <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" :colon="false" :form="form">
                <a-row>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="议题编号">
                            <a-input v-decorator="[
                                'number',
                                {
                                    rules: [
                                        { required: true, message: '请输入！' },
                                    ],
                                },
                            ]" style="width: 200px"></a-input>
                        </a-form-item>
                    </a-col>

                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="上报部门">
                        <a-select placeholder="" show-search :filter-option="$selectFilterOption" style="width: 200px" v-decorator="[
                                            'deptId',
                                            { rules: [{ required: true, message: '请选择！' }] },
                                        ]">
                            <a-select-option v-for="item in organizationList" :key="item.id" :value="item.id">{{ item.uniqueName
                                            }}</a-select-option>
                        </a-select>
                        </a-form-item>
                    </a-col>

                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="部门领导">
                            <EmployeeSelector
                            style="width: 200px"
                                @change="
                                (arr) => {
                                    if (arr.length > 0) {
                                    selectedCharger = arr[0];
                                    } else {
                                    selectedCharger = {};
                                    }
                                }
                                "
                            >
                                <a-button block style="text-align: left">
                                <div v-if="selectedCharger.name">
                                    {{ selectedCharger.name }}
                                </div>
                                <div v-else>&nbsp;</div>
                                </a-button>
                            </EmployeeSelector>
                        </a-form-item>
                    </a-col>

                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="议题名称">
                            <a-input v-decorator="[
                                'name',
                                {
                                    rules: [
                                        { required: true, message: '请输入！' },
                                    ],
                                },
                            ]" style="width: 200px"></a-input>
                        </a-form-item>
                    </a-col>

                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="议题扼要">
                            <a-input v-decorator="[
                                'brief',
                                {
                                    rules: [
                                        { required: true, message: '请输入！' },
                                    ],
                                },
                            ]" style="width: 200px"></a-input>
                        </a-form-item>
                    </a-col>

                </a-row>

                <div class="center">
                    <a-space>
                        <a-button @click="$close($route.path)">关闭</a-button>
                        <a-button type="primary" :loading="loading" @click="handleSubmit"
                            >保存</a-button
                        >
                        <a-button type="primary" :loading="loading" @click="process"
                            >保存并发布</a-button
                        >
                    </a-space>
                </div>
            </a-form>
        </a-card>
    </div>
</template>

<script>
import request from "@/api/request";
import organization from "@/mixins/organization";
import EmployeeSelector from "@/components/employee-selector";
function save(data) {
    return request({
        url: "/office-service/quality/issue/add",
        method: "post",
        data
    });
}
export default {
    name: "safeDailyWorkAdd",
    mixins: [organization],
    components: {
        EmployeeSelector,
    },
    data() {
        return {
            selectedCharger: {}, 
            form: this.$form.createForm(this),
            companyList: [],
            projectList: [],
            loading: false,
        };
    },
    mounted() {
    },
    methods: {
        process(e) {
            e.preventDefault();
            this.hanldeSave(true);
        },
        handleSubmit(e) {
            e.preventDefault();
            this.hanldeSave(false);
        },
        hanldeSave(isRaise) {
            this.form.validateFields((err, values) => {
                if (!err) {
                    this.loading = true;
                    save({
                        deptMasterId: this.selectedCharger.userId,
                        brief: values.brief,
                        deptId: values.deptId,
                        deptName: this.organizationList.filter(v=>v.id == values.deptId)[0].uniqueName,
                        name: values.name,
                        number: values.number,
                        isRaise: isRaise,
                    })
                        .then(() => {
                            this.$close(this.$route.path);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        }
    },
};
</script>


<style lang="less" scoped>
.container {
    padding: 12px;
}

.center {
    margin-top: 80px;
    margin-bottom: 80px;
}
</style>